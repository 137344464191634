import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()

const controllers = import.meta.glob(
  '../controllers/**/errors_controller.js*',
  {
    eager: true,
  }
)

registerControllers(application, controllers)
